#models button {
  width: 100%;
  padding: 1em 0;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  color: #E5E4E2;
  background: #333;
  border: none;
  line-height: 1;
  font-size: 1.25rem;
  text-transform: uppercase;
  background-color: transparent;
}

#models button:hover {
  background-color: rgba(51, 51, 51, 0.025);
}

#models button:hover > img {
  animation-play-state: paused;
}

#models button img {
  animation: marquee 5s linear infinite;
margin: 0 25px;
}
.model-title{
    text-align: center;
    font-size: 1.735rem;
    font-weight: 800;
    line-height: 2.5rem;
    margin-bottom: 20px

  }

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
span{
   color:linear-gradient(0deg, #5ADCFF, #5ADCFF);

}