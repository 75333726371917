/* RequireAuth.css */

.require-auth {
    text-align: center;
    padding: 20px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 20px auto;
    max-width: 300px;
  }
  
  .auth-message {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .auth-button {
    background-color: var(--main-color);
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .auth-button:hover {
    background-color: #5e11b6;
  }
  