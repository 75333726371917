.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
   
  }
  
  .not-found-title {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: rgb(90, 5, 150);
  }
  
  .not-found-text {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  
  .not-found-link {
    display: block;
  width: 300px;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
  background-color: rgb(90, 5, 150);
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
  }
  
  .not-found-link:hover {
    color: #ffffff;
  }
  @media screen and (max-width: 768px) {
    .not-found-container {
     margin: auto 20px;
     
    }
    .not-found-title {
      font-size: 3rem;
      margin-bottom: 1rem;
      color: rgb(90, 5, 150);
    }
    
    .not-found-text {
      font-size: 1.5rem;
      margin-bottom: 2rem;
    } 
  }