/* footer.css */
.footer {
  background-color: var(--black);
  color: rgb(0, 0, 0);
  padding: 40px 50px;
  padding-left: 140px;
}

.footer-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.footer-column {
  flex: 1;
  margin-right: 20px;
}

.footer-title {
  font-size: 20px;
  margin-bottom: 15px;
  color: orange;
}

.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin-bottom: 8px;
}

.footer-links a {
  text-decoration: none;
  color: rgb(215, 213, 213);
  font-size: 14px;
}

.social-icons {
  display: flex;
  flex-direction: column;
}

.social-icons a {
  text-decoration: none;
  color: rgb(215, 213, 213);
  font-size: 16px;
  margin-right: 15px;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .footer {
    padding: 0;
    padding-left: 40px;
  }
  .footer-column {
    flex: 1 0 100%;
    margin-right: 0;
    border-bottom: 1px solid grey;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: left;
  }

  .footer-row {
    flex-direction: column;
    justify-content: left;
  }

  .social-icons {
    margin-top: 20px;
  }
}
