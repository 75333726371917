@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Medium.ttf") format("truetype");
}

:root {
  --main-color: #00ffea;
  --black: #13131a;
  --bg: #ffffff;
  --border: 0.1rem solid rgba(255, 255, 255, 0.3);
  --border-raduis: 8px;
}
* {
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  text-decoration: none;
  transition: 0.2s linear;
}

a {
  text-decoration: none !important;
}

.main-button a {
  font-size: 14px;
  color: #fff;
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
  padding: 12px 30px;
  display: inline-block;
  border-radius: 25px;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
}

.main-button a:hover {
  background-color: #fff;
  color: var(--main-color);
  border: 1px solid #fff;
}
.error-message {
  color: #ff3333;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
  margin-bottom: 10px;
}

.success-message {
  color: #33cc33;
  font-size: 14px;
  margin-top: 5px;
  text-align: center;
}
.success-div {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  margin-top: 10px;
  text-align: center;
}

/* active sidebar link in dashboard */
.active-link {
  background: #000;
}

button:hover {
  box-shadow: rgba(23, 23, 162, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
